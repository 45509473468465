import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ShopDetailsType76 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
						<img src={publicUrl+"assets/img/img-slide/banner76-2.png"} alt="Image" />
						<img src={publicUrl+"assets/img/img-slide/banner76-3.png"} alt="Image" />
						<img src={publicUrl+"assets/img/img-slide/banner76-4.png"} alt="Image" />
						<img src={publicUrl+"assets/img/img-slide/banner76-5.jpg"} alt="Image" />


						<h1>Type 80</h1>
						<h4 className="title-2">Description</h4>
						<p>The View merupakan proyek masterpiece terbaru Hekta Karya, Hunian yang terletak 
							di lokasi strategis yang menjadi pusat bisnis berkembang yang menjadi 
							value proyek ini sangat tinggi, hekta karya sebagai pengembang terdepan 
							sangat memahami generasi milenial yang membutuhkan rumah gaya hidup modern 
							sekaligus berinvestasi dengan mengusung konsep alam yang sangat kuat untuk 
							mendukung anda dalam memulai gaya hidup sehat, melalui desain tropis yang 
							menghadirkan rooftop dan batu alam yang kokoh konsep ini mampu menjadi 
							pelepas penat keluarga anda, karna sangat menyatu dengan keindahan alam</p>
						<h4 className="title-2">Facts and Features</h4>
						<div className="property-detail-feature-list clearfix mb-45">                            
						<ul>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-garage" />
								<div>
								<h6>2 Carpot</h6>
								<small>Roof Garden</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>3 Kamar Tidur</h6>
								<small>Open Space</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-bathtub" />
								<div>
								<h6>2 Kamar Mandi</h6>
								<small>Laundry Room</small>
								</div>
							</div>
							</li>
						</ul>
						</div>
					
						
						
						
					</div>
					</div>
					<div className="col-lg-4">
					<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
						
						
						
						
						<div className="widget ltn__banner-widget d-none go-top">
						<Link to="/shop"><img src={publicUrl+"assets/img/banner/2.jpg"} alt="#" /></Link>
						</div>
					</aside>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ShopDetailsType76