import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-115 pb-100 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
			          <img src={publicUrl+"assets/img/img-slide/banner76-5.jpg"} alt="About Us Image" />
			          <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
			            <div className="ltn__video-img ltn__animation-pulse1">
			              <img src={publicUrl+"assets/img/others/8.png"} alt="video popup bg image" />
			              <a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
			                <i className="fa fa-play" />
			              </a>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">About Us</h6>
			            <h1 className="section-title">Investasi untuk Kesejahteraan dan Masa Depan Anda</h1>
			            <p>The View adalah proyek hunian terbaru Hekta Karya dengan lokasi strategis 
							di pusat bisnis berkembang. Proyek ini mengusung konsep alam dan desain 
							tropis, memberikan gaya hidup modern dan kesehatan melalui roof garden 
							yang indah.</p>
			          </div>                        
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon-house-4" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="service-details.html">Design Modern Tropical</a></h4>
			              <p>Design Terkini yang memaksimalkan gaya hidup sehat melalui perancangan cahaya alami dalam rumah dengan sistem airflow yang baik sehingga udara yang masuk tetap terjaga</p>
			            </div>
			          </div>
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon flaticon-park" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="service-details.html">Roof Garden</a></h4>
			              <p>Rasakan kenyamanan tinggal di Cluster Rumah kami dengan keunggulan lokasi di Lantai Ke-3 yang dilengkapi dengan Roof Garden yang luas. Nikmati momen santai sambil menikmati pemandangan indah di sekitar perumahan The View</p>
			            </div>
			          </div>
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><i className="flaticon-maps-and-location" /></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="service-details.html">Lokasi Strategis</a></h4>
			              <p>Cluster The View menawarkan lokasi yang strategis di pusat kota bisnis dan gaya hidup yang berkembang, sangat terjangkau karena dekat dengan BSD dan Bintaro.</p>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV3