import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';

class NavbarV6 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
		let imgPath = 'assets/img/cover.png';
        let imgattr = 'logo'
        let anchor = '#'
        return (
		<div>
			<style>
  {`
    .sticky-active.ltn__sticky-bg-black {
      background: rgba(0, 0, 0, 0.2) url('${publicUrl}${imgPath}') repeat;
      backdrop-filter: blur(5px); /* Sesuaikan dengan tingkat blur yang diinginkan */
    }
  `}
</style>
           <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2">
				{/* ltn__header-middle-area start */}
				<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black" >
					<div className="container">
					<div className="row">
						<div className="col">
							<div className="site-logo-wrap">
								<div className="site-logo go-top">
									<Link to="/">
										<img
										src={publicUrl + 'assets/img/logo-5.png'}
										alt="Logo"
										// style={{ width: '300px', height: '200px' }}  // Ganti ukuran sesuai keinginan Anda
										/>
									</Link>
								</div>
							</div>
						</div>
						<div className="col header-menu-column menu-color-white">
						<div className="header-menu d-none d-xl-block go-top">
							<nav>
							<div className="ltn__main-menu">
								<ul>
									<li><a href="https://hektakarya.com/">About Hekta Karya</a></li>
									<li><a href="https://siteplan.theview.co.id">Siteplan</a></li>
									<li><a href="https://theview.co.id/theviewvirtual/">3D Virtual</a></li>
									<li><a href="https://www.youtube.com/@hektakarya9516">Youtube</a></li>

								{/* <li className="special-link">
									<Link to="/add-listing">Add Listing</Link>
								</li> */}
								
								</ul>
							</div>
							</nav>
						</div>
						</div>
						<div className="col--- ltn__header-options ltn__header-options-2 ">
						{/* Mobile Menu Button */}
						<div className="mobile-menu-toggle d-xl-none">
							<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
							<svg viewBox="0 0 800 600">
								<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
								<path d="M300,320 L540,320" id="middle" />
								<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
							</svg>
							</a>
						</div>
						</div>
					</div>
					</div>
				</div>
				{/* ltn__header-middle-area end */}
			</header>

			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/logo-5.png"} alt="Logo" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
					<div className="ltn__utilize-menu-search-form">
					<form action={"#"}>
						<input type="text" placeholder="Search..." />
						<button><i className="fas fa-search" /></button>
					</form>
					</div>
					<div className="ltn__utilize-menu">
					<ul>
						<li><a href="https://hektakarya.com/">About Hekta Karya</a></li>
						<li><a href="https://siteplan.theview.co.id">Siteplan</a></li>
						<li><a href="https://theview.co.id/theviewvirtual/">3D Virtual</a></li>
						<li><a href="https://www.youtube.com/@hektakarya9516">Youtube</a></li>
					</ul>
					</div>
					{/* <div className="ltn__social-media-2">
					<ul>
						<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
					</div> */}
				</div>
				</div>
		</div>
        )
    }
}


export default NavbarV6