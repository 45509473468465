import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV12 from './components/home-v12';


import ProdductDetails from './components/product-details';
import ProdductDetailsType72 from './components/product-details-type-72';
import ProdductDetailsType76 from './components/product-details-type-76';
import ProdductDetailsType92 from './components/product-details-type-92';



class Root extends Component {
    render() {
        return(
                <HashRouter basename="/">
	                <div>
	                <Switch>
	                    <Route exact path="/" component={HomeV12} />
                        

                        <Route path="/product-details" component={ ProdductDetails } />
                        <Route path="/product-type-72" component={ ProdductDetailsType72 } />
                        <Route path="/product-type-80" component={ ProdductDetailsType76 } />
                        <Route path="/product-type-92" component={ ProdductDetailsType92 } />

                            
	                </Switch>
	                </div>
                </HashRouter>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
