import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CategoryV1 extends Component {
	// handleClick = (event) => {
    //     event.preventDefault();
    //     console.log('Clicked on the image.'); // Pastikan ini muncul di konsol

    //     // Implementasikan logika atau tindakan yang Anda inginkan di sini
    //     // Contoh: Buka gambar dalam Lightcase
    //     // Jika menggunakan Lightcase, pastikan skrip Lightcase dimuat dengan benar di halaman Anda
    //     window.lightcase.start({ href: event.currentTarget.href });
    // }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-90 go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Facilities</h6>
			          <h1 className="section-title">Our Facilities</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
						<a href={publicUrl+"assets/img/img-slide/row-12.jpg"} data-rel="lightcase:myCollection">
							<span className="category-icon"><i className="flaticon-car" /></span>
							<span className="category-title">Row 12</span>
							<span className="category-btn"><i className="flaticon-right-arrow" /></span>
						</a>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
						<a href={publicUrl+"assets/img/img-slide/pool.jpg"} data-rel="lightcase:myCollection">
							<span className="category-icon"><i className="flaticon-swimming" /></span>
							<span className="category-title">Mini Pool</span>
							<span className="category-btn"><i className="flaticon-right-arrow" /></span>
			          	</a>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
						<a href={publicUrl+"assets/img/img-slide/one-gate.jpg"} data-rel="lightcase:myCollection">
							<span className="category-icon"><i className="flaticon-secure-shield" /></span>
							<span className="category-title">Private Security</span>
							<span className="category-btn"><i className="flaticon-right-arrow" /></span>
			          	</a>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
						<a href={publicUrl+"assets/img/img-slide/club-house.jpg"} data-rel="lightcase:myCollection">
							<span className="category-icon"><i className="flaticon flaticon-house-2" /></span>
							<span className="category-title">Club House</span>
							<span className="category-btn"><i className="flaticon-right-arrow" /></span>
						</a>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
						<a href={publicUrl+"assets/img/img-slide/jogging.jpg"} data-rel="lightcase:myCollection">
							<span className="category-icon"><i className="flaticon flaticon-fast-forward-double-right-arrows-symbol" /></span>
							<span className="category-title">Jogging Track</span>
							<span className="category-btn"><i className="flaticon-right-arrow" /></span>
			          	</a>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
						<a href={publicUrl+"assets/img/img-slide/smart-door.png"} data-rel="lightcase:myCollection">
							<span className="category-icon"><i className="flaticon flaticon-house-key" /></span>
							<span className="category-title">Smart Door Lock</span>
							<span className="category-btn"><i className="flaticon-right-arrow" /></span>
			         	</a>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
						<a href={publicUrl+"assets/img/img-slide/healty-home.png"} data-rel="lightcase:myCollection">
							<span className="category-icon"><i className="flaticon-home-2" /></span>
							<span className="category-title">Healty Homes</span>
							<span className="category-btn"><i className="flaticon-right-arrow" /></span>
			          	</a>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
						<a href={publicUrl+"assets/img/img-slide/kids-play.png"} data-rel="lightcase:myCollection">
							<span className="category-icon"><i className="flaticon-slider" /></span>
							<span className="category-title">Kid’s Playland</span>
							<span className="category-btn"><i className="flaticon-right-arrow" /></span>
			          	</a>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default CategoryV1