import React from 'react';
import Navbar from './global-components/navbar-v6';
import PageHeader from './global-components/page-header';
import ProductSliderV1Type72 from './shop-components/product-slider-v1-type72';
import ProductDetailsType72 from './shop-components/shop-details-type72';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Product_Details_Type72 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Product Details" customclass="mb-0" />
        <ProductSliderV1Type72 />
        <ProductDetailsType72 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Product_Details_Type72

