import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TeamV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__team-area pt-115 pb-90 go-top slide-to-side">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Meet Our Marketing Professional</h6>
						<h1 className="section-title">Our Sales</h1>
					</div>
					</div>
				</div>
				<div className="row justify-content-center ltn__team-slider-one-active slick-arrow-1">
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/didi.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Didi</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6281289308517"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/agus.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Agus</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6281255260038"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-4---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/untung.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Untung</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6281287250800"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-5---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/joko.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Joko</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6289517111635"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-6---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/ophie.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Ophie</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6281316605560"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-7---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/akram.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Akram</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6282113135616"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-9---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/rullie.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Rullie</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6281219540006"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-10---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/dedi.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Dedi</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6281320003498"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-11---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/sekar.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Sekar</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6282140532389"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className="ltn__team-item ltn__team-item-11---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/dedi.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4><Link to="/team-details">Dedi</Link></h4>
						
						<div className="ltn__social-media">
							<ul>
							<li><a href="https://wa.me/6281320003498"><i className="fab fa-whatsapp" style={{ fontSize: '28px' }} /></a></li>
							</ul>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default TeamV1