import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map ">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.745664845718!2d106.70106070000001!3d-6.2971157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb9d281a6b25%3A0x3d7e20dd2b0a7267!2sThe%20View%20Official!5e0!3m2!1sid!2sid!4v1704133361030!5m2!1sid!2sid" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
		</div>
        }
}

export default Map